import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { userStore } from "../../store/user";
import { IoMenuOutline } from "react-icons/io5";
import logo from "../../assets/alertsify.svg";

const Header = () => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false);
  const gentradeToken = localStorage.getItem("gentradeToken");
  const subDomainUrl = localStorage.getItem('baseURL')
  const { setUserData } = userStore((state) => state);

  useEffect(() => {
    if (isOpen) {
      // Prevent scrolling of the body when the navbar is open
      document.body.style.overflow = "hidden";
    } else {
      // Restore scrolling when the navbar is closed
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  const handleSignOut = () => {
    localStorage.clear();
    if(subDomainUrl && gentradeToken){
      localStorage.clear();
      setUserData(null);
      window.location.href = `${subDomainUrl}/signIn`
    }
    else{
      localStorage.clear();
      navigate("/signIn");
      setUserData(null);
    }
    setUserData(null);
  };

  return (
    <div className="bg-[#101010] md:px-8 flex md:items-center mx-auto md:h-16 sticky top-0 z-50">
      {/* For larger screens */}
      <div className="hidden md:block relative w-full">
        <div 
          onClick={() => {
            if(subDomainUrl && gentradeToken){
              window.location.href = subDomainUrl
            }
            else{
              navigate("/")
            }
          }}
         className="absolute left-0 top-1/2 transform -translate-y-1/2 text-2xl text-white font-bold cursor-pointer">
          <img src={logo} className="size-16" />
        </div>
        {gentradeToken && (
        <div className="absolute hidden md:flex left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 justify-between gap-20 items-center mx-auto text-[#AFAFAF]">
          <Link to="/dashboard" className=" hover:text-tertiary">
            Dashboard
          </Link>
          <Link to="/statistics" className=" hover:text-tertiary">
            User History
          </Link>
          {/* <div className="hover:text-tertiary">About</div>
          <div className="hover:text-tertiary">Pricing</div>
          <div className="hover:text-tertiary">Contact</div> */}
        </div>
        )}
        {!gentradeToken ? (
          <div className="absolute hidden md:flex right-0 top-1/2 transform -translate-y-1/2 text-[#AFAFAF]">
            <Link to="https://whop.com/tradeproelite/?%3Fpass=prod_oP4t99lFAfErW" className="pb-[6px] pt-[6px] text-tertiary border-2 border-tertiary rounded-full px-2 w-[140px] text-center">
              Discord Server
            </Link>
            <Link
              to="/signIn"
              className="bg-[#DAFE6A] px-8 pb-[6px] pt-[6px] rounded-full font-semibold text-black ml-4"
            >
              Sign In
            </Link>
          </div>
        ) : (
          <div className="absolute hidden md:flex right-0 top-1/2 transform -translate-y-1/2 text-[#AFAFAF]">
            <Link to="https://whop.com/tradeproelite/?%3Fpass=prod_oP4t99lFAfErW" className="pb-[6px] pt-[6px] text-tertiary border-2 border-tertiary rounded-full px-2 w-[140px] text-center">
              Discord Server
            </Link>
            <button
              onClick={handleSignOut}
              className="bg-[#DAFE6A] px-8 pb-[6px] pt-[5px] rounded-full font-semibold text-black ml-4"
            >
              Sign Out
            </button> 
          </div>
        )}
      </div>

      {/* For smaller screens  */}
      <div className="md:hidden bg-[#101010] px-8 flex items-center mx-auto h-16 w-full z-50">
        <div className="relative w-full">
          <div 
            onClick={() => {
              setIsOpen(false)
              navigate("/")}
            } 
            className="absolute left-0 top-1/2 transform -translate-y-1/2 text-xl text-white font-bold"
          >
            <img src={logo} className="size-16" />
          </div>

          <div className="absolute right-0 top-1/2 transform -translate-y-1/2 flex text-[#AFAFAF]">
            <IoMenuOutline
              size={30}
              className=" "
              onClick={() => setIsOpen(!isOpen)}
            />
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="h-screen absolute top-0 right-0 w-[230px] ml-auto bg-[#101010] z-40">
          {!gentradeToken ? (
            <div className="text-white w-[80%] mx-auto font-semibold mt-20">
              <div className="flex flex-col gap-2 items-center justify-center text-[12.36px] rounded-lg px-4 py-2 mt-2">
                {/* <LuHistory /> */}
                <Link to="https://whop.com/tradeproelite/?%3Fpass=prod_oP4t99lFAfErW" className="">
                <button
                  onClick={() => {
                    setIsOpen(false)
                  }}
                  className="text-black bg-tertiary px-8 pb-[6px] pt-[5px] rounded-lg font-bold "
                >
                  Discord
                </button>
              </Link>
                <Link
                  to="/signIn"
                  onClick={() => setIsOpen(false)}
                  className="border border-[#DAFE6A] text-tertiary px-8 pb-[6px] pt-[5px] rounded-lg font-semibold "
                >
                  Sign In
                </Link>
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-between text-white w-[80%] mx-auto font-semibold mt-20">
              <div className="flex flex-col gap-2 items-center text-[12.36px] rounded-lg px-4 py-2 mt-2">
                {/* <LuHistory /> */}
                <Link
                  to="/dashboard"
                  onClick={() => setIsOpen(false)}
                  className="bg-[#DAFE6A px-2 w-full text-cente pb-[6px] pt-[5px] rounded-lg font-bold text-[#AFAFAF]"
                >
                  Dashboard
                </Link>
                <Link
                  to="/statistics"
                  onClick={() => setIsOpen(false)}
                  className="bg-[#DAFE6A px-2 w-full text-cente pb-[6px] pt-[5px] rounded-lg font-bold text-[#AFAFAF]"
                >
                  User History
                </Link>
              </div>
              <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 text-center">
              <Link to="https://whop.com/tradeproelite/?%3Fpass=prod_oP4t99lFAfErW" className="">
                <button
                  onClick={() => {
                    setIsOpen(false)
                  }}
                  className="pb-[6px] pt-[6px] mx-auto text-tertiary border-2 border-tertiary rounded-lg px-2  w-full text-center"
                >
                  Discord
                </button>
              </Link>
                <button
                  onClick={() => {
                    setIsOpen(false)
                    handleSignOut()
                  }}
                  className="bg-tertiary px-4 w-[120px] text-center pb-[6px] pt-[5px] mt-4 rounded-lg font-bold text-black"
                >
                  Sign Out
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Header;
