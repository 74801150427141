import React, { useState } from "react";
import { userStore } from "../../store/user";
import { Link } from "react-router-dom";

import profile from "../../assets/defaultPicture.png";
import logo from "../../assets/alertsify.svg";
import Skeleton from "react-loading-skeleton";

const DashHeader = () => {
  const [showDialog, setShowDialog] = useState(false)
  const {
    userData,
    btoData,
    hasBrokerTokens,
    setHasBrokerTokens,
    selectedAccount,
    setSelectedAccount,
    accountList,
    setAccountList,
    brokerBalance,
    isPaperTrade,
    setIsPaperTrade,
  } = userStore((state) => state);

  const {
    avatarUrl,
    discordServers = [],
    name,
    id: userId,
    role,
    currentBalance,
  } = userData || {};

  const handleToggleChange = () => {
    // if (!isPaperTrade) {
      setShowDialog(true);  // Show the dialog when toggled on
    // }
    // setIsPaperTrade(!isPaperTrade);
  }

  return (
    <div
      className={`lg:flex ${
        hasBrokerTokens ? "justify-between" : "justify-end"
      } items-center gap-4 mb-4`}
    >
      {showDialog && (
        <div className="fixed w-full h-screen left-0 top-0 z-40">
          <div className="absolute left-0 right-0 w-full h-full bg-black z-20" />
          <div className="absolute left-1/2 top-1/2 w-[80%] lg:w-auto transform -translate-x-1/2 -translate-y-1/2 z-30 bg-[#111111] p-6 rounded-lg text-white">
            {/* <div className="flex justify-end">

            </div> */}
            <div class="md:flex items-center">
              <div class="rounded-full border border-gray-300 flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto">
                <img src={logo} alt="" />
              </div>
              <div class="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                <p class="font-bold">Feature coming out soon....</p>
                <p class="text-sm text-gray-100 mt-1">Switching to real trades will be available soon</p>
              </div>
            </div>
            <div class="text-center md:text-right mt-4 md:flex md:justify-end">
              <button class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 border border-black bg-red-600 green-800 text-white green-600 rounded-lg font-semibold text-sm md:ml-2 md:order-2" onClick={() => setShowDialog(false)}>Close</button>
            </div>
          </div>
        </div>
      )}
      {hasBrokerTokens && (
        <div className="flex flex-col lg:flex-row gap-2 lg:gap-4 lg:items-center">
          <div
            className={`w-fit flex gap-2  [#C4C4C4]  border ${
              isPaperTrade
                ? "border-[#646464] text-tertiary"
                : "border-tertiary bg- tertiary text-[#111111]"
            } transition-all rounded-lg text-center px-2 py-1`}
          >
            <label class="inline-flex gap-2 items-center mb- 5 py-[2px] cursor-pointer">
              <span className={`${isPaperTrade ? "text-tertiary" : "text-[#646464]" } ms -3 text-sm font-medium text-gray -900 dark:text-gray- 300`}>
                Paper Trades
              </span>
              <input
                type="checkbox"
                onChange={handleToggleChange}
                // onChange={() => setIsPaperTrade(!isPaperTrade)}
                // disabled
                checked={!isPaperTrade}
                class="sr-only peer"
              />
              <div class="relative w-9 h-5 bg- gray-200 peer-focus:outline-none peer-focus:ring- 2 peer-focus:ring-white blue-300 dark:peer-focus:ring-white dark:checked:peer-focus:ring-black checked:peer-focus:ring-black blue-800 rounded-full peer dark:bg-[#646464] gray-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] before: peer- after:bg-white after: peer-checked:after:bg-black [#111111] after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-tertiary"></div>
              <span className={`${isPaperTrade ? "text-[#646464]" : "text-tertiary" } ms -3 text-sm font-medium text-gray -900 dark:text-gray- 300`}>
                Real Trades
              </span>
            </label>
          </div>

          <div className="w-fit flex gap-2 text-tertiary [#C4C4C4]  border border-[#646464] rounded-lg text-center px-2 py-1">
            Broker Account:
            {accountList ? (
              <select
                className="text-white bg-transparent border-none outline-none px-2 py- 1"
                value={selectedAccount}
                onChange={(e) => setSelectedAccount(e.target.value)}
              >
                {accountList?.map((item, index) => (
                  <option value={item.id} className="bg-black text-white p-2">
                    {item.id}
                  </option>
                ))}
              </select>
            ) : (
              <Skeleton
                baseColor="#424242"
                highlightColor="#202020"
                width="80px"
              />
            )}
          </div>
        </div>
      )}
      <div className="lg: flex flex-col lg:flex-row mt-2 lg:mt-0 justify-end gap-2 lg:gap-4">
        {hasBrokerTokens && (
          <div className="w-fit flex gap-2 text-tertiary [#C4C4C4]  border border-[#646464] rounded-lg text-center px-2 py-1">
            Broker Account Balance:
            <div className="text-white">
              {brokerBalance?.availableFunds !== undefined ? (
                brokerBalance.availableFunds
              ) : (
                <Skeleton
                  baseColor="#424242"
                  highlightColor="#202020"
                  width="60px"
                />
              )}
            </div>
          </div>
        )}
        <div className="w-fit flex gap-2 text-tertiary [#C4C4C4]  border border-[#646464] rounded-lg text-center px-2 py-1">
          Paper Trade Balance:
          <div className="text-white">{currentBalance}</div>
        </div>
        <div className="hidden lg:flex">
          {role == "admin" ? (
            <Link
              to={"/admin/userInfo"}
              className="text-tertiary px-2 py-1 transition-all border border-[#646464] hover:border-tertiary text-center rounded-lg"
            >
              Admin Portal
            </Link>
          ) : (
            ""
          )}
          <img
            src={avatarUrl || profile}
            alt="profile"
            className="ml-6 border border-white rounded-full size-[34px]"
          />
        </div>
      </div>
    </div>
  );
};

export default DashHeader;
